<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 sm8 md8 lg11 py-6>
        <v-layout wrap>
          <v-flex xs12 sm6 md3 lg3 pl-4 pr-4 pr-sm-0 pt-6>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="fromDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From"
                  outlined
                  readonly
                  dense
                  hide-details
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                scrollable
                @change="$refs.menu.save(fromDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 pl-4 pr-4 pr-sm-0 pt-6>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="toDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To"
                  readonly
                  outlined
                  dense
                  hide-details
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                scrollable
                @change="$refs.menu2.save(toDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 pl-4 pr-4 pr-sm-0 pt-6>
            <v-text-field
              v-model="keyword"
              dense
              hide-details
              label="Search : Ad.no/Name"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 pl-4 pr-4 pr-sm-0 pt-6>
            <v-autocomplete
              :items="kindofCloth"
              v-model="clothtype"
              label="Cloth Type"
              item-text="name"
              item-value="_id"
              dense
              hide-details
              clearable
              outlined
              @input="getData()"
            ></v-autocomplete>
            <!-- <v-select
              :items="kindofCloth"
              v-model="clothtype"
              label="Cloth Type"
              outlined
              item-text="name"
              item-value="_id"
              dense
              clearable
            ></v-select> -->
          </v-flex>
        </v-layout>

        <v-card elevation="0" class="rounded-xl ma-6">
          <v-layout wrap>
            <v-flex xs12>
              <span class="nsbold" style="font-size: 18px" pa-2
                >Overall Garments washed</span
              >
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex
              xs12
              v-for="(value, i) in sample"
              :key="i"
              :style="i % 2 == 0 ? 'background-color:#eeeeee' : ''"
            >
              <v-layout wrap>
                <v-flex
                  text-left
                  xs6
                  px-1
                  v-if="value.data[0].clothid.clothType"
                >
                  <span
                    class="nsregular"
                    v-if="value.data[0].clothid.clothType.name"
                    style="font-size: 18px"
                    >{{ value.data[0].clothid.clothType.name }}
                  </span>
                </v-flex>
                <v-flex text-right xs6 px-1>
                  <span class="nsregular" style="font-size: 18px">
                    Count: {{ value.count }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <v-card class="pa-6"  align="center" outlined>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap class="pb-2">
                <v-flex xs12 lg4 text-left>
                  <span class="nsbold" style="font-weight: 600; font-size: 24px"
                    >Laundry History</span
                  >
                </v-flex>
                <v-flex xs12 lg4>
                  <v-select
                    :items="HostelList"
                    v-model="hostel"
                    label="Hostel"
                    item-text="hostelName"
                    item-value="_id"
                    clearable
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 lg2 px-lg-2 py-3 py-lg-0>
                  <v-select
                    :items="cstatus"
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 lg2 class="hidden-xs-only">
                  <download-excel :data="myData" :fields="json_fields">
                    <v-btn
                      width="155px"
                      dark
                      style="text-transform: none"
                      color="#766BC0"
                      >Download Excel</v-btn
                    >
                  </download-excel>
                </v-flex>
                 <v-flex xs12 lg2 class="hidden-sm-and-up">
                  <download-excel :data="myData" :fields="json_fields">
                    <v-btn
                      block
                      dark
                      style="text-transform: none"
                      color="#766BC0"
                      >Download Excel</v-btn
                    >
                  </download-excel>
                </v-flex>
              </v-layout>

              <v-layout wrap v-if="student">
                <v-flex xs12 class="text-left" v-if="student.length > 0">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr
                          class="nsbold"
                          style="background-color: #eeeeee; font-size: 16px"
                        >
                          <th class="text-left">No.</th>
                          <th class="text-left">QR Id</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Ad.No</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Last In</th>
                          <th class="text-left">Last Out</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in student" :key="i" elevation="0">
                          <td>
                            <span
                              v-if="page == 1"
                              class="nsregular"
                              style="font-size: 16px"
                            >
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{ item.clothid.clothbarid }}</td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/profile?id=' + item.studentid._id"
                            >
                              <span v-if="item.studentid.name">
                                {{ item.studentid.name }}
                              </span>

                              <span v-else>-</span>
                            </router-link>
                          </td>

                          <td>
                            <span v-if="item.studentid.admissionNo">
                              {{ item.studentid.admissionNo }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.clothid.clothType.name">
                              {{ item.clothid.clothType.name }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.INdate">
                              {{ formatDate(item.INdate) }}
                            </span>
                            <span v-else>-</span>
                          </td>

                          <td>
                            <span v-if="item.OUTdate">
                              {{ formatDate(item.OUTdate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <!-- <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentclothes?id=' + item.studentid._id"
                            >
                            <v-icon small style="border:1px solid #766bc0;border-radius:50px;cursor: pointer; color: #766bc0">
                              mdi-information-variant
                            </v-icon>
                          
                            </router-link>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- <v-card round style="background-color: #eeeeee">
                    <v-layout wrap pa-2 class="nsbold" style="font-size: 16px">
                      <v-flex xs2>QR Id</v-flex>
                      <v-flex xs2>Name</v-flex>
                      <v-flex xs1>Ad.No</v-flex>
                      <v-flex xs1>Type</v-flex>
                      <v-flex xs3>Last In</v-flex>
                      <v-flex xs3>Last Out</v-flex>
                    </v-layout>
                  </v-card>
                  <v-card v-for="(item, i) in student" :key="i" elevation="0">
                    <v-layout
                      wrap
                      pa-2
                      class="nsregular"
                      style="font-size: 16px"
                    >
                      <v-flex xs2>{{ item.clothid.clothbarid }}</v-flex>
                      <v-flex xs2 v-if="item.studentid">
                        <span v-if="item.studentid.name">
                          {{ item.studentid.name }}
                        </span>
                      </v-flex>
                      <v-flex xs1>
                        <span v-if="item.studentid.admissionNo">
                          {{ item.studentid.admissionNo }}
                        </span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs1 v-if="item.clothid.clothType">
                        <span v-if="item.clothid.clothType.name">
                          {{ item.clothid.clothType.name }}
                        </span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs3>
                        <span v-if="item.INdate">
                          {{ formatDate(item.INdate) }}
                        </span>
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs2>
                        <span v-if="item.OUTdate">
                          {{ formatDate(item.OUTdate) }}
                        </span>
                        <span v-else>-</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-card> -->
                </v-flex>
                <v-flex xs12 align-self-center text-center pa-4 v-else>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>

              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="test"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
        <!----------------------------------------->

        <!----------------------------------------->
        <v-snackbar
          :timeout="3000"
          :value="true"
          right
          color="red"
          v-model="showsnackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      menu: false,
      menu2: false,
      menu22: false,
      menu33: false,
      kindofCloth: null,
      kindofclothtype: null,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      clothtype: null,
      ServerError: false,
      curUrl: "/view/clothstatus",
      hostel: null,
      test: 0,
      page: 1,
      Pagelength: 0,
      appLoading: false,
      a: "getlist/status/in",
      b: "getlist/history/status/out",
      cstatus: ["All", "IN", "OUT"],
      cvalue: "All",
      //pagination
      sample: [],
      limit: 20,
      HostelList: [],
      myData: [],
      excel: {
        qrid: "",
        name: "",
        adno: "",
        type: "",
        lastin: "",
        lastout: "",
        hostel: "",
        status: "",
      },
      json_fields: {
        BarCode_Id: "qrid",
        Name: "name",
        Ad_No: "adno",
        Type: "type",
        Last_In: "lastin",
        Last_Out: "lastout",
        Hostel: "hostel",
        Status: "status",
      },
      // pages: 0,
      // pageCount: 0,
      // currentPage: 1
      student: null,
      user: [],
      tab: null,
      msg: null,
      statusItems: null,
      mylist: [],
      showsnackbar: false,
      items: ["web", "shopping", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
    this.getExcel();
    // this.pendingData();
    this.gethostel();
    this.getClothType();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    hostel() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.page > 1) this.page = 1;
this.getExcel();
      this.getData();
    },

    // from() {

    //   this.pendingData();
    // },
    // to() {

    //   this.pendingData();
    // },
    clothtype() {
      if (this.page > 1) this.page = 1;
      // this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
    cvalue() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
  },
  methods: {
    getClothType() {
      // this.appLoading = true;
      axios({
        method: "get",
        url: "/cloth/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.kindofCloth = response.data.data;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          // this.appLoading = false;
        });
    },
    pickMyUnit2(val) {
      for (let i = 0; i < this.kindofCloth.length; i++) {
        if (this.kindofCloth[i]._id == val) {
          this.unitType = this.kindofCloth[i].quantityType;
          break;
        }
      }
    },
    getData() {
      this.appLoading = true;

      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      // console.log("a=", a);

      axios({
        method: "post",
        url: "/cloth/history/in/out",
        headers: {
          token: localStorage.getItem("token"),
        },

        data: {
          //   id: this.$route.query.id,
          keyword: this.keyword,
          clothtype: this.clothtype,
          fromDate: this.fromDate,
          toDate: this.toDate,
          page: this.page,
          limit: 20,
          cstatus: a,
          hostelname: this.hostel,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.myData = [];
            this.student = response.data.data;
            this.sample = response.data.summary;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
            this.test = Math.ceil(response.data.totalLength / 20);
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].clothid) {
                this.excel.qrid = response.data.data[i].clothid.clothbarid;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              //  this.excel.qrid = "qrid";
              // this.excel.name ="name";
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }

              if (response.data.data[i].clothid.clothType.name) {
                this.excel.type = response.data.data[i].clothid.clothType.name;
              }
              if (response.data.data[i].INdate) {
                this.excel.lastin = response.data.data[i].INdate;
              }

              if (response.data.data[i].OUTdate) {
                this.excel.lastout = response.data.data[i].OUTdate;
              }
              if (response.data.data[i].studentid.studentHostel) {
                this.excel.hostel =
                  response.data.data[i].studentid.studentHostel.hostelName;
              }
              if (response.data.data[i].presentStatus) {
                this.excel.status = response.data.data[i].presentStatus;
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }

            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          // this.appLoading = false;
          console.log(err);
        });
    },
     getExcel() {
      this.appLoading = true;

      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      // console.log("a=", a);

      axios({
        method: "post",
        url: "/cloth/history/in/out",
        headers: {
          token: localStorage.getItem("token"),
        },

        data: {
          //   id: this.$route.query.id,
          keyword: this.keyword,
          clothtype: this.clothtype,
          fromDate: this.fromDate,
          toDate: this.toDate,
         
          cstatus: a,
          hostelname: this.hostel,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.myData = [];
            // this.student = response.data.data;
            // this.sample = response.data.summary;
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].clothid) {
                this.excel.qrid = response.data.data[i].clothid.clothbarid;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              //  this.excel.qrid = "qrid";
              // this.excel.name ="name";
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }

              if (response.data.data[i].clothid.clothType.name) {
                this.excel.type = response.data.data[i].clothid.clothType.name;
              }
              if (response.data.data[i].INdate) {
                this.excel.lastin = response.data.data[i].INdate;
              }

              if (response.data.data[i].OUTdate) {
                this.excel.lastout = response.data.data[i].OUTdate;
              }
              if (response.data.data[i].studentid.studentHostel) {
                this.excel.hostel =
                  response.data.data[i].studentid.studentHostel.hostelName;
              }
              if (response.data.data[i].presentStatus) {
                this.excel.status = response.data.data[i].presentStatus;
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }

            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          // this.appLoading = false;
          console.log(err);
        });
    },
    gethostel() {
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    pendingData() {
      // this.appLoading = true;
      axios({
        method: "post",
        url: "laundry/history/clothtype/count",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          from: this.from,
          to: this.to,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.sample = response.data.summary;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
